export const actionTypes = {
  GET_BTI_DOCUMENTS: 'GET_BTI_DOCUMENTS',
  GET_BTI_DOCUMENTS_SUCCESS: 'GET_BTI_DOCUMENTS_SUCCESS',
  GET_BTI_DOCUMENTS_REQUEST: 'GET_BTI_DOCUMENTS_REQUEST',
  GET_BTI_DOCUMENTS_ERROR: 'GET_BTI_DOCUMENTS_ERROR',
};

export const getBtiDocuments = (bulkId, btiDocumentsCurrentId) => ({ type: actionTypes.GET_BTI_DOCUMENTS, bulkId, btiDocumentsCurrentId});

export const getBtiDocumentsSuccess = (btiDocuments, btiDocumentsCurrentId) => ({ type: actionTypes.GET_BTI_DOCUMENTS_SUCCESS, btiDocuments, btiDocumentsCurrentId });

export const getBtiDocumentsRequest = () => ({ type: actionTypes.GET_BTI_DOCUMENTS_REQUEST });

export const getBtiDocumentsError = (btiDocumentsError) => ({ type: actionTypes.GET_BTI_DOCUMENTS_ERROR, btiDocumentsError });

