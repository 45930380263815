export const actionTypes = {
  GET_PROJECT_DOCUMENTS: 'GET_PROJECT_DOCUMENTS',
  GET_PROJECT_DOCUMENTS_SUCCESS: 'GET_PROJECT_DOCUMENTS_SUCCESS',
  GET_PROJECT_DOCUMENTS_REQUEST: 'GET_PROJECT_DOCUMENTS_REQUEST',
  GET_PROJECT_DOCUMENTS_ERROR: 'GET_PROJECT_DOCUMENTS_ERROR',
};

export const getProjectDocuments = (bulkId, projectDocumentsCurrentId) => ({ type: actionTypes.GET_PROJECT_DOCUMENTS, bulkId, projectDocumentsCurrentId});

export const getProjectDocumentsSuccess = (projectDocuments, projectDocumentsCurrentId) => ({ type: actionTypes.GET_PROJECT_DOCUMENTS_SUCCESS, projectDocuments, projectDocumentsCurrentId });

export const getProjectDocumentsRequest = () => ({ type: actionTypes.GET_PROJECT_DOCUMENTS_REQUEST });

export const getProjectDocumentsError = (projectDocumentsError) => ({ type: actionTypes.GET_PROJECT_DOCUMENTS_ERROR, projectDocumentsError });
