export const actionTypes = {
  GET_APPLICATIONS: 'GET_APPLICATIONS',
  GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
  GET_APPLICATIONS_REQUEST: 'GET_APPLICATIONS_REQUEST',
  GET_APPLICATIONS_ERROR: 'GET_APPLICATIONS_ERROR',

  CREATE_APPLICATION: 'CREATE_APPLICATION',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_REQUEST: 'CREATE_APPLICATION_REQUEST',
  CREATE_APPLICATION_ERROR: 'CREATE_APPLICATION_ERROR',

  PAY_APPLICATION: 'PAY_APPLICATION',
  PAY_APPLICATION_SUCCESS: 'PAY_APPLICATION_SUCCESS',
  PAY_APPLICATION_REQUEST: 'PAY_APPLICATION_REQUEST',
  PAY_APPLICATION_ERROR: 'PAY_APPLICATION_ERROR',

  DELETE_APPLICATION: 'DELETE_APPLICATION',
  DELETE_APPLICATION_SUCCESS: 'DELETE_APPLICATION_SUCCESS',
  DELETE_APPLICATION_REQUEST: 'DELETE_APPLICATION_REQUEST',
  DELETE_APPLICATION_ERROR: 'DELETE_APPLICATION_ERROR',
};

export const getApplications = () => ({ type: actionTypes.GET_APPLICATIONS });

export const getApplicationsRequest = () => ({ type: actionTypes.GET_APPLICATIONS_REQUEST });

export const getApplicationsSuccess = (applications) => ({
  type: actionTypes.GET_APPLICATIONS_SUCCESS,
  applications,
});

export const getApplicationsError = (err) => ({ type: actionTypes.GET_APPLICATIONS_ERROR, err });

export const createApplication = (values) => ({ type: actionTypes.CREATE_APPLICATION, values });

export const createApplicationRequest = () => ({ type: actionTypes.CREATE_APPLICATION_REQUEST });

export const createApplicationSuccess = () => ({
  type: actionTypes.CREATE_APPLICATION_SUCCESS,
});

export const createApplicationError = (err) => ({
  type: actionTypes.CREATE_APPLICATION_ERROR,
  err,
});

export const payApplication = (id, debit) => ({ type: actionTypes.PAY_APPLICATION, id, debit });

export const payApplicationRequest = () => ({ type: actionTypes.PAY_APPLICATION_REQUEST });

export const payApplicationSuccess = (id) => ({
  type: actionTypes.PAY_APPLICATION_SUCCESS,
  id,
});

export const payApplicationError = (err) => ({
  type: actionTypes.PAY_APPLICATION_ERROR,
  err,
});

export const deleteApplication = (id) => ({ type: actionTypes.DELETE_APPLICATION, id });

export const deleteApplicationRequest = () => ({ type: actionTypes.DELETE_APPLICATION_REQUEST });

export const deleteApplicationSuccess = (id) => ({
  type: actionTypes.DELETE_APPLICATION_SUCCESS,
  id,
});

export const deleteApplicationError = (err) => ({
  type: actionTypes.DELETE_APPLICATION_ERROR,
  err,
});
