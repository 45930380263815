import { actionTypes } from './actions';

const initialState = {
  getAppsRequest: false,
  getAppsSuccess: false,
  getAppsError: false,
  applications: [],

  createAppRequest: false,
  createAppSuccess: false,
  createAppError: false,

  payAppRequest: false,
  payAppSuccess: false,
  payAppError: false,

  deleteAppRequest: false,
  deleteAppSuccess: false,
  deleteAppError: false,
};

const handleApplicationsRequest = (state) => ({
  ...state,
  getAppsRequest: true,
  getAppsSuccess: false,
  getAppsError: false,
});

const handleApplicationsSuccess = (state, action) => ({
  ...state,
  getAppsRequest: false,
  getAppsSuccess: true,
  getAppsError: false,
  applications: action.applications,
});

const handleApplicationsError = (state) => ({
  ...state,
  getAppsRequest: false,
  getAppsSuccess: false,
  getAppsError: true,
});

const handleCreateApplicationRequest = (state) => ({
  ...state,
  createAppRequest: true,
  createAppSuccess: false,
  createAppError: false,
});

const handleCreateApplicationSuccess = (state) => ({
  ...state,
  createAppRequest: false,
  createAppSuccess: true,
  createAppError: false,
});

const handleCreateApplicationError = (state) => ({
  ...state,
  createAppRequest: false,
  createAppSuccess: false,
  createAppError: true,
});

const handlePayApplicationRequest = (state) => ({
  ...state,
  payAppRequest: true,
  payAppSuccess: false,
  payAppError: false,
});

const handlePayApplicationSuccess = (state) => ({
  ...state,
  payAppRequest: false,
  payAppSuccess: true,
  payAppError: false,
});

const handlePayApplicationError = (state, action) => ({
  ...state,
  payAppRequest: false,
  payAppSuccess: false,
  payAppError: action.err || true,
});

const handleDeleteApplicationRequest = (state) => ({
  ...state,
  deleteAppRequest: true,
  deleteAppSuccess: false,
  deleteAppError: false,
});

const handleDeleteApplicationSuccess = (state, { id }) => ({
  ...state,
  deleteAppRequest: false,
  deleteAppSuccess: true,
  deleteAppError: false,
  applications: state.applications.filter((item) => item.id !== id),
});

const handleDeleteApplicationError = (state) => ({
  ...state,
  deleteAppRequest: false,
  deleteAppSuccess: false,
  deleteAppError: true,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_APPLICATIONS_REQUEST]: handleApplicationsRequest,
    [actionTypes.GET_APPLICATIONS_SUCCESS]: handleApplicationsSuccess,
    [actionTypes.GET_APPLICATIONS_ERROR]: handleApplicationsError,

    [actionTypes.CREATE_APPLICATION_REQUEST]: handleCreateApplicationRequest,
    [actionTypes.CREATE_APPLICATION_SUCCESS]: handleCreateApplicationSuccess,
    [actionTypes.CREATE_APPLICATION_ERROR]: handleCreateApplicationError,

    [actionTypes.PAY_APPLICATION_REQUEST]: handlePayApplicationRequest,
    [actionTypes.PAY_APPLICATION_SUCCESS]: handlePayApplicationSuccess,
    [actionTypes.PAY_APPLICATION_ERROR]: handlePayApplicationError,

    [actionTypes.DELETE_APPLICATION_REQUEST]: handleDeleteApplicationRequest,
    [actionTypes.DELETE_APPLICATION_SUCCESS]: handleDeleteApplicationSuccess,
    [actionTypes.DELETE_APPLICATION_ERROR]: handleDeleteApplicationError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
