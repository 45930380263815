import { actionTypes } from './actions';

const initialState = {
  customer: null,
  getCustomerRequest: false,
  getCustomerSuccess: false,
  getCustomerError: false,
  confirmEmailRequest: false,
  confirmEmailSuccess: false,
  confirmEmailError: false,
};

const handleUpdateCustomer = (state, action) => ({
  ...state,
  customer: action.customer,
});

const handleGetCustomerRequest = (state) => ({
  ...state,
  getCustomerRequest: false,
  getCustomerSuccess: false,
  getCustomerError: false,
});

const handleGetCustomerSuccess = (state, action) => ({
  ...state,
  getCustomerRequest: false,
  getCustomerSuccess: true,
  getCustomerError: false,
  customer: action.customer,
});

const handleGetCustomerError = (state, action) => ({
  ...state,
  getCustomerRequest: false,
  getCustomerSuccess: false,
  getCustomerError: action.error,
});

const handleConfirmEmailRequest = (state) => ({
  ...state,
  confirmEmailRequest: true,
  confirmEmailSuccess: false,
  confirmEmailError: false,
});

const handleConfirmEmailSuccess = (state, action) => ({
  ...state,
  confirmEmailRequest: false,
  confirmEmailSuccess: true,
  confirmEmailError: false,
  data: action.data,
});

const handleConfirmEmailError = (state, action) => ({
  ...state,
  loading: false,
  confirmEmailRequest: false,
  confirmEmailSuccess: false,
  confirmEmailError: action.error,
});


export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.UPDATE_CUSTOMER]: handleUpdateCustomer,
    [actionTypes.GET_CUSTOMER_REQUEST]: handleGetCustomerRequest,
    [actionTypes.GET_CUSTOMER_SUCCESS]: handleGetCustomerSuccess,
    [actionTypes.GET_CUSTOMER_ERROR]: handleGetCustomerError,
    [actionTypes.CONFIRM_EMAIL_REQUEST]: handleConfirmEmailRequest,
    [actionTypes.CONFIRM_EMAIL_SUCCESS]: handleConfirmEmailSuccess,
    [actionTypes.CONFIRM_EMAIL_ERROR]: handleConfirmEmailError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
