export const actionTypes = {
  GET_COMPARISON: 'GET_COMPARISON',
  GET_COMPARISON_SUCCESS: 'GET_COMPARISON_SUCCESS',
  GET_COMPARISON_REQUEST: 'GET_COMPARISON_REQUEST',
  GET_COMPARISON_ERROR: 'GET_COMPARISON_ERROR',
};

export const getComparison = () => ({ type: actionTypes.GET_COMPARISON });

export const getComparisonSuccess = (comparisons) => ({ type: actionTypes.GET_COMPARISON_SUCCESS, comparisons });

export const getComparisonRequest = () => ({ type: actionTypes.GET_COMPARISON_REQUEST });

export const getComparisonError = (error) => ({ type: actionTypes.GET_COMPARISON_ERROR, error });
