import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ScrollContainer, InformationPlate,
} from '@pik/pik-ui';
import { formatText } from '@pik/pik-utils';

import { changeCurrentId } from '../../services/deals/actions';

import { S } from './styles';

const { getRooms, getPropertyType, capitaliseFirstLetter } = formatText;

const handleItemClick = ({ id }, changeCurrentIdAction) => changeCurrentIdAction(id);

const STICKER = {
  all: 'Кладовые и машиноместа в продаже',
  storehouse: 'Кладовые в продаже',
  carPlace: 'Машиноместа в продаже',
};

const getSticker = (carPlaceCount, storehouseCount) => {
  let sticker;

  if (carPlaceCount) {
    sticker = STICKER.carPlace;
  }

  if (storehouseCount) {
    sticker = STICKER.storehouse;
  }

  if (carPlaceCount && storehouseCount) {
    sticker = STICKER.all;
  }

  return sticker;
};

const renderItem = (item, currentId, changeCurrentIdAction, showStickers, isSmallPlate) => {
  const {
    id,
    type_id: typeId,
    rooms, area,
    block: {
      isCommercial,
      name: blockName,
      carplace_count: carPlaceCount,
      storehouse_count: storehouseCount,
    },
  } = item;
  const active = currentId === id;
  // const iconList = ['flat', 'flat', 'flat', 'commercial', 'carPlace', 'storehouse'];
  // const icon = iconList[typeId - 1] ? iconList[typeId - 1] : null;
  const isFlat = typeId === 1 || typeId === 2;
  let title = capitaliseFirstLetter(`${isFlat ? getRooms(rooms, typeId) : getPropertyType(typeId)}, ${area} м²`);
  if (typeId === 4) {
    title = title.replace('Коммерческое помещение', 'Помещение');
  }

  if (typeId === 6) {
    title = title.replace('Кладовое помещение', 'Кладовая');
  }

  const sticker = showStickers && getSticker(carPlaceCount, storehouseCount);

  return (
    <S.Item key={id}>
      <InformationPlate
        title={title}
        onClick={(e, params) => handleItemClick(params, changeCurrentIdAction)}
        onClickParams={{ id }}
        description={blockName}
        // sticker={sticker}
        active={active}
        small={isSmallPlate}
      />
    </S.Item>
  );
};

const SwitchObjects = ({
  deals, currentId, changeCurrentIdAction, showSwitchObjects, showStickers,
}) => {
  const haveDeals = deals && Object.keys(deals) && Object.keys(deals).length;

  if (haveDeals && showSwitchObjects) {
    let isSmallPlate = true;
    if (showStickers) {
      Object.keys(deals).forEach((id) => {
        const { block } = deals[id];
        const { carplace_count: carPlaceCount, storehouse_count: storehouseCount } = block;
        if (carPlaceCount > 0 || storehouseCount > 0) {
          isSmallPlate = false;
        }
      });
    }

    return (
      <S.SwitchObjects small={isSmallPlate}>
        <ScrollContainer>
          { Object.keys(deals).map((id) => renderItem(
            deals[id], currentId, changeCurrentIdAction, showStickers, isSmallPlate,
          )) }
        </ScrollContainer>
      </S.SwitchObjects>
    );
  }

  return null;
};

SwitchObjects.propTypes = {
  deals: PropTypes.shape({}),
  currentId: PropTypes.string,
  changeCurrentIdAction: PropTypes.func.isRequired,
  showSwitchObjects: PropTypes.bool.isRequired,
  showStickers: PropTypes.bool.isRequired,
};

SwitchObjects.defaultProps = {
  deals: null,
  currentId: null,
};

const mapStateToProps = ({ dealsService: { deals, currentId } }) => ({
  deals, currentId,
});

const mapDispatchToProps = {
  changeCurrentIdAction: changeCurrentId,
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchObjects);
