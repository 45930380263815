import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetCustomer,
  apiConfirmEmail,
} from './api';

import {
  actionTypes,
  getCustomerRequest,
  getCustomerSuccess,
  getCustomerError,
  confirmEmailRequest,
  confirmEmailSuccess,
  confirmEmailError,
} from './actions';


function* getCustomerSaga() {
  try {
    yield put(getCustomerRequest());
    const customer = yield call(apiGetCustomer);

    yield put(getCustomerSuccess(customer));
  } catch (error) {
    console.error(error);
    yield put(getCustomerError(error.data));
  }
}

function* confirmEmailSaga({ key }) {
  try {
    yield put(confirmEmailRequest());
    const data = yield call(() => apiConfirmEmail({ key }));
    yield put(confirmEmailSuccess(data));
  } catch (error) {
    console.error(error);
    const { data: { error: errMsg } } = error;
    yield put(confirmEmailError(JSON.stringify(errMsg)));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_CUSTOMER, getCustomerSaga);
  yield takeEvery(actionTypes.CONFIRM_EMAIL, confirmEmailSaga);
}
