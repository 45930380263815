import api from '../../common/api';

export const apiRecovery = (data) => api.pikApi.fetch('POST', '/v1/auth/password', data, {
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiRecoveryPut = (data) => api.pikApi.fetch('PUT', '/v1/auth/password', data);

export const apiRegistration = (data) => api.pikApi.fetch('POST', '/v1/auth/checkcustomer', data);

export const apiRecoveryPassport = (data) => api.pikApi.fetch('POST', '/v1/auth-phone', data);

export const apiRecoveryPutPassport = (data) => api.pikApi.fetch('PUT', '/v1/auth-phone', data);

export const apiRegistrationPassword = (data) => api.pikApi.fetch('PUT', '/v1/auth/password', data);
