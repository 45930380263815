import { actionTypes } from './actions';

const initialState = {
  projectDocuments: null,
  projectDocumentsLoading: false,
  projectDocumentsError: false,
  projectDocumentsCurrentId: null,
};

const handleProjectDocumentsSuccess = (state, { projectDocuments, projectDocumentsCurrentId }) => ({
  ...state,
  projectDocumentsLoading: false,
  projectDocumentsCurrentId,
  projectDocuments,
});

const handleProjectDocumentsRequest = (state) => ({
  ...state,
  projectDocumentsLoading: true,
});

const handleProjectDocumentsError = (state, { projectDocumentsError }) => ({
  ...state,
  projectDocumentsLoading: false,
  projectDocumentsError,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_PROJECT_DOCUMENTS_SUCCESS]: handleProjectDocumentsSuccess,
    [actionTypes.GET_PROJECT_DOCUMENTS_REQUEST]: handleProjectDocumentsRequest,
    [actionTypes.GET_PROJECT_DOCUMENTS_ERROR]: handleProjectDocumentsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
