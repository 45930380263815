import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetBtiDocuments,
} from './api';

import {
  actionTypes,
  getBtiDocumentsSuccess,
  getBtiDocumentsRequest,
  getBtiDocumentsError,
} from './actions';

function* getBtiDocumentsSaga({ bulkId, btiDocumentsCurrentId }) {
  try {
    yield put(getBtiDocumentsRequest());
    const btiDocuments = yield call(() => apiGetBtiDocuments(bulkId));
    yield put(getBtiDocumentsSuccess(btiDocuments, btiDocumentsCurrentId));
  } catch (btiDocumentsError) {
    console.error(btiDocumentsError);
    yield put(getBtiDocumentsError(btiDocumentsError));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_BTI_DOCUMENTS, getBtiDocumentsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
