import { actionTypes } from './actions';
import { requestCodeText } from './constants';

const getErrorText = (error) => requestCodeText[error.data.error];

const initialState = {
  loading: false,
  error: null,
  status: null,
  authKey: null,
};

const handleSendSmsSuccess = (state, action) => ({
  ...state,
  loading: false,
  status: action.message,
});

const handleSendSmsRequest = (state) => ({
  ...state,
  loading: true,
});

const handleSendSmsError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const handleCheckSmsSuccess = (state, action) => ({
  ...state,
  loading: false,
  status: action.message,
  authKey: action.authKey,
});

const handleCheckSmsRequest = (state) => ({
  ...state,
  loading: true,
});

const handleCheckSmsError = (state, action) => ({
  ...state,
  loading: false,
  error: getErrorText(action.error),
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_SMS_SUCCESS]: handleSendSmsSuccess,
    [actionTypes.GET_SMS_REQUEST]: handleSendSmsRequest,
    [actionTypes.GET_SMS_ERROR]: handleSendSmsError,
    [actionTypes.CHECK_SMS_SUCCESS]: handleCheckSmsSuccess,
    [actionTypes.CHECK_SMS_REQUEST]: handleCheckSmsRequest,
    [actionTypes.CHECK_SMS_ERROR]: handleCheckSmsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
