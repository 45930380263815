import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetApplications,
  apiCreateApplication,
  apiPayApplication,
  apiDeleteApplication,
} from './api';

import {
  actionTypes,
  getApplicationsRequest,
  getApplicationsSuccess,
  getApplicationsError,

  createApplicationRequest,
  createApplicationSuccess,
  createApplicationError,

  payApplicationRequest,
  payApplicationSuccess,
  payApplicationError,

  deleteApplicationRequest,
  deleteApplicationSuccess,
  deleteApplicationError,
} from './actions';

function* getApplicationsSaga() {
  try {
    yield put(getApplicationsRequest());
    const apps = yield call(() => apiGetApplications());
    yield put(getApplicationsSuccess(apps));
  } catch (err) {
    console.error(err);
    yield put(getApplicationsError(err));
  }
}

function* createApplicationSaga({ values }) {
  try {
    yield put(createApplicationRequest());
    const response = yield call(() => apiCreateApplication(values));

    if (response.paymentUrl) {
      document.location.href = response.paymentUrl;
    }

    yield put(createApplicationSuccess());
  } catch (err) {
    console.error(err);
    yield put(createApplicationError(err));
  }
}

function* payApplicationSaga({ id, debit = 0 }) {
  try {
    yield put(payApplicationRequest());
    const response = yield call(() => apiPayApplication(id, debit));

    if (response.paymentUrl && !debit) {
      document.location.href = response.paymentUrl;
    }

    yield put(payApplicationSuccess());
  } catch (err) {
    console.error(err);
    yield put(payApplicationError(err && err.data && err.data.error));
  }
}

function* deleteApplicationSaga({ id }) {
  try {
    yield put(deleteApplicationRequest());
    const response = yield call(apiDeleteApplication, id);

    console.log('response delete', response);

    if (response && response.message === 'success') {
      yield put(deleteApplicationSuccess(id));
    }
  } catch (err) {
    console.error('delete error', err);

    yield put(deleteApplicationError(err));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_APPLICATIONS, getApplicationsSaga);
  yield takeEvery(actionTypes.CREATE_APPLICATION, createApplicationSaga);
  yield takeEvery(actionTypes.PAY_APPLICATION, payApplicationSaga);
  yield takeEvery(actionTypes.DELETE_APPLICATION, deleteApplicationSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
