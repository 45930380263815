import { cookie } from '@pik/pik-utils';
import { actionTypes } from './actions';

const { checkCookie } = cookie;

const initialState = {
  deals: {},
  loading: true,
  error: false,
  showBanner: false,
  currentId: null,
};

const handleShowBanner = (deals, ctx) => {
  const guidArray = ['a4341ddd-db30-e811-893d-00505688958b', '6cd0a2bf-5a33-e811-893d-00505688958b', '47A8CEFF-5B33-E811-893D-00505688958B', '8f54f4b6-5b33-e811-893d-00505688958b'];
  const cookies = checkCookie('hidePikBannerPavlova40', ctx);
  let show = false;

  if (deals && !cookies) {
    Object.keys(deals).map((deal) => {
      const currentDeal = deals[deal];
      const currentBulk = currentDeal && currentDeal.bulk;
      if (currentBulk && currentBulk.guid && guidArray.indexOf(currentBulk.guid) !== -1) {
        show = true;
      }
    });
  }

  return show;
};

const handleDealsRequest = (state) => ({
  ...state,
  loading: true,
  showBanner: false,
});

const handleDealsSuccess = (state, action) => ({
  ...state,
  loading: false,
  deals: action.deals,
  currentId: action.currentId,
  showBanner: handleShowBanner(action.deals, action.ctx),
});

const handleDealsError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
  showBanner: false,
});

const handleChangeCurrentId = (state, { currentId }) => ({
  ...state,
  currentId,
});

const handleHideBanner = (state) => ({
  ...state,
  showBanner: false,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_DEALS_SUCCESS]: handleDealsSuccess,
    [actionTypes.GET_DEALS_REQUEST]: handleDealsRequest,
    [actionTypes.GET_DEALS_ERROR]: handleDealsError,
    [actionTypes.CHANGE_CURRENT_ID]: handleChangeCurrentId,
    [actionTypes.HIDE_BANNER_PAVLOVA]: handleHideBanner,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
