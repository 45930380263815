import styled, { createGlobalStyle } from 'styled-components';
import { media, mixins, styleHelpers } from '@pik/pik-ui';

const { colors } = styleHelpers;

export const Container = styled.div(({ hideOnScroll, isHidden }) => `
  padding: 0 16px;
  position: fixed;
  width: calc(100% - 32px);
  background-color: #fff;
  border-bottom: 1px solid #F0F0F5;
  z-index: 100;
  
  > div {
    height: 100%;
  }
  
  @media ${media.max768} {
    height: 48px;
    ${hideOnScroll ? `
      ${mixins.animation('top')};
      top: 88px;
      ${isHidden ? `
        top: -48px;
      ` : ''}
    ` : ''}
  }
`);

export const ActiveLink = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .Icons.arrowDown {
    transition: transform 0.3s ease;
    
    ${({open}) => open ? `
      transform: rotate(180deg);
    ` : ''}
  }
`;

export const ActiveCurrentLink = styled.div`
  ${mixins.flex('row', 'nowrap', 'flex-start')};
  .IconsPack {
    width: 100%;
    height: 100%;
    svg {
      path, circle, rect {
        stroke: ${colors.formaGrayDark};
      }
    }
  }
`;

export const ActiveCurrentLinkIcon = styled.div`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  margin-right: 14px;
`;

export const ActiveLinkText = styled.div`
  font-family: GT America;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${media.max768} {
    font-size: 15px;
  }
`;

export const LinksContainer = styled.div`
  position: absolute;
  left: 0;
  top: 48px;
  height: auto;
  width: 100%;
  overflow: hidden;
  transition: visibility .35s ease-in-out;
  visibility: hidden;

  ${({ open }) => open && `
    visibility: visible;
    transition: none;
  `}

  @media ${media.max768} {
    background: #fff;
    max-height: calc((var(--vh, 1vh) * 100) - 128px);
    overflow-y: auto !important;
    top: 40px;
  }
`;

export const Menu = styled.div`
  position: relative;
  padding: 0;
  height: auto;
  width: auto;
  background: #fff;
  transform: translateY(-100%);
  transition: transform .35s ease-in-out;

  ${({ open }) => open && `
    transform: translateY(0);
  `}
`;

export const MenuList = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;

  @media ${media.max768} {
    padding: 0 15px;
  }
`;


export const GlobalBlackoutStyle = createGlobalStyle`
  ${({ open }) => open && `
    .blackout {
      visibility: visible;
      opacity: 1;
      z-index: 99;
    }
  `}
`;

export const MenuItem = styled.li`
  height: 100%;
  width: 100%;
  user-select: none;
  white-space: nowrap;
  padding: 19px 0;
  overflow: hidden;

  @media ${media.max768} {
    padding: 15px 0;
  }

  .Link {
    opacity: 1;
    height: 100%;
    width: 100%;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition: color .3s ease;
    font-family: GT America;
    text-transform: none;
    letter-spacing: 0;
    color: ${colors.formaGrayDark};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  ${({ active }) => active && `
    .Link {
      color: ${colors.formaGrayDark} !important;
      font-weight: 700;
    }
  `}
`;
