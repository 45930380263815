import styled from 'styled-components';
import { mixins, media } from '@pik/pik-ui';

export const S = {};

S.SwitchObjects = styled.div`
  box-sizing: border-box;
  margin-bottom:${({ small }) => (small ? 24 : 48)}px;
  margin-top: 44px;
  
  @media ${media.max768} {
    margin-bottom:${({ small }) => (small ? -2 : 16)}px;
    margin-top: 6px;
  }
`;

S.Item = styled.div`
  box-sizing: border-box;
  margin-right: 32px;
  min-height: 88px;
  ${mixins.flexWidth('256px')};
  
  @media ${media.max1120} {
    margin-right: 23px;
  }
  
  @media ${media.max768} {
  
    margin-right: 8px;
    &:first-child {
      margin-left: 16px;
    }
    &:last-child { 
      border-right: solid 16px transparent;
    }
  }
 
  &:last-child {
    margin-right: 0;
  }
`;
