import api from '../../common/api';

export const apiGetApplications = () => api.pikApi.fetch('GET', '/v1/measuring');
export const apiCreateApplication = (data) => api.pikApi.fetch('POST', '/v1/measuring', data);
export const apiPayApplication = (id, debit) => api.pikApi.fetch('PUT', '/v1/measuring', { id, debit });
export const apiDeleteApplication = (id) => api.pikApi.fetch('DELETE', `/v1/measuring?id=${id}`);

// 1) Получение списка заявок - GET без параметров
// 2) Создание заявки - POST
// {
//  "opportunityId": 96612,
//  "flatId": 313822,
//  "drawingType": 1,
//  "email": "ds@eru",
//  "phone": "+7(925)033-90-90"
// }
// 3) повторная оплата - PUT
// {
//  "id":1 # measuring id (т.е. id заявки)
// }
// 4) удаление заявки DELETE id=1
