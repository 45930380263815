import { actionTypes } from './actions';
import { requestCode, requestCodeText, screenStates } from './constants';

const initialState = {
  screenState: screenStates.GET_PHONE,
  phone: null,
  sendCode: false,
  loading: false,
  error: null,
  codeApprove: false,
  codeApproveError: null,
  authKey: null,
  changePassword: false,
  changePasswordError: null,
};

const handleChangeResetRecovery = () => ({
  ...initialState,
});

const handleChangeScreenState = (state, action) => ({
  ...state,
  error: null,
  loading: false,
  screenState: action.screenState,
});

const handleSendCodeRequest = (state, action) => ({
  ...state,
  phone: action.phone.phone,
  loading: true,
  error: null,
});

const handleSendCodeSuccess = (state, action) => ({
  ...state,
  sendCode: action.data,
  loading: false,
  error: null,
});

const handleSendCodeError = (state, action) => {
  const checkErrorData = action.error && action.error.data && action.error.data.error;

  let error = null;
  let screenState = 'PASSPORT';

  if (checkErrorData) {
    const { data: { error: errorKey } } = action.error;

    const isCaptcha = errorKey === requestCode.ERR_WRONG_CAPTCHA;

    if (isCaptcha) {
      error = requestCodeText[errorKey];
      screenState = state.screenState;
    }
  }

  return {
    ...state,
    loading: false,
    screenState,
    error,
  };
};

const handleSendCodeApproveRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleSendCodeApproveSuccess = (state, action) => ({
  ...state,
  loading: false,
  authKey: action.data.auth_key,
  codeApprove: true,
  codeApproveError: null,
});

const handleSendCodeApproveError = (state, action) => ({
  ...state,
  loading: false,
  codeApproveError: requestCodeText[action.error.data.error],
});

const handleSendNewPasswordRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleSendNewPasswordSuccess = (state) => ({
  ...state,
  loading: false,
  changePassword: true,
});

const handleSendNewPasswordError = (state, action) => ({
  ...state,
  loading: false,
  changePasswordError: requestCodeText[action.error.data.error],
});

const handleClearService = () => ({
  ...initialState,
});

const handleApprovePassportRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleApprovePassportSuccess = (state, { data: { cid } }) => ({
  ...state,
  loading: false,
  error: null,
  screenState: 'CREATE_NEW_PHONE',
  cid,
});

const handleApprovePassportError = (state, action) => ({
  ...state,
  loading: false,
  error: requestCodeText[action.error.data.error],
});

const handleNewPhoneSendCodeRequest = (state, { phone }) => ({
  ...state,
  loading: true,
  error: null,
  ...phone,
});

const handleNewPhoneSendCodeSuccess = (state) => ({
  ...state,
  loading: false,
  error: null,
  screenState: 'NEW_PHONE_CODE_APPROVE',
});

const handleNewPhoneSendCodeError = (state, action) => ({
  ...state,
  loading: false,
  error: requestCodeText[action.error.data.error],
});

const handleApproveCodeRegistrationRequest = (state) => ({
  ...state,
  loading: true,
  error: null,
});

const handleApproveCodeRegistrationSuccess = (state, { data: { authKey } }) => ({
  ...state,
  loading: false,
  error: null,
  authKey,
  screenState: 'CREATE_PASSWORD',
});

const handleApproveCodeRegistrationError = (state, action) => ({
  ...state,
  loading: false,
  error: requestCodeText[action.error.data.error],
});

const handleNewPasswordRegistrationRequest = (state) => ({
  ...state,
  loading: false,
  error: null,
});

const handleNewPasswordRegistrationSuccess = (state) => ({
  ...state,
  loading: false,
  changePassword: true,
  error: null,
});

const handleNewPasswordRegistrationError = (state, action) => ({
  ...state,
  loading: false,
  error: requestCodeText[action.error.data.error],
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.RESET_RECOVERY_DATA]: handleChangeResetRecovery,
    [actionTypes.CHANGE_RECOVERY_STATE]: handleChangeScreenState,
    [actionTypes.SEND_RECOVERY_CODE_REQUEST]: handleSendCodeRequest,
    [actionTypes.SEND_RECOVERY_CODE_SUCCESS]: handleSendCodeSuccess,
    [actionTypes.SEND_RECOVERY_CODE_ERROR]: handleSendCodeError,
    [actionTypes.SEND_RECOVERY_APPROVE_CODE_REQUEST]: handleSendCodeApproveRequest,
    [actionTypes.SEND_RECOVERY_APPROVE_CODE_SUCCESS]: handleSendCodeApproveSuccess,
    [actionTypes.SEND_RECOVERY_APPROVE_CODE_ERROR]: handleSendCodeApproveError,
    [actionTypes.SEND_RECOVERY_NEW_PASSWORD_REQUEST]: handleSendNewPasswordRequest,
    [actionTypes.SEND_RECOVERY_NEW_PASSWORD_SUCCESS]: handleSendNewPasswordSuccess,
    [actionTypes.SEND_RECOVERY_NEW_PASSWORD_ERROR]: handleSendNewPasswordError,
    [actionTypes.ACTION_CLEAR_SERVICE]: handleClearService,
    [actionTypes.ACTION_APPROVE_PASSPORT_REQUEST]: handleApprovePassportRequest,
    [actionTypes.ACTION_APPROVE_PASSPORT_SUCCESS]: handleApprovePassportSuccess,
    [actionTypes.ACTION_APPROVE_PASSPORT_ERROR]: handleApprovePassportError,
    [actionTypes.ACTION_SEND_REGISTRATION_CODE_REQUEST]: handleNewPhoneSendCodeRequest,
    [actionTypes.ACTION_SEND_REGISTRATION_CODE_SUCCESS]: handleNewPhoneSendCodeSuccess,
    [actionTypes.ACTION_SEND_REGISTRATION_CODE_ERROR]: handleNewPhoneSendCodeError,
    [actionTypes.ACTION_APPROVE_CODE_REGISTRATION_REQUEST]: handleApproveCodeRegistrationRequest,
    [actionTypes.ACTION_APPROVE_CODE_REGISTRATION_SUCCESS]: handleApproveCodeRegistrationSuccess,
    [actionTypes.ACTION_APPROVE_CODE_REGISTRATION_ERROR]: handleApproveCodeRegistrationError,
    [actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_REQUEST]: handleNewPasswordRegistrationRequest,
    [actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_SUCCESS]: handleNewPasswordRegistrationSuccess,
    [actionTypes.ACTION_REGISTRATION_NEW_PASSWORD_ERROR]: handleNewPasswordRegistrationError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
