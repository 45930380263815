import { actionTypes } from './actions';

const initialState = {
  data: null,
  getFooterRequest: false,
  getFooterSuccess: false,
  getFooterError: false,
};

const handleUpdateFooter = (state, action) => ({
  ...state,
  data: action.data,
});

const handleGetFooterRequest = (state) => ({
  ...state,
  getFooterRequest: false,
  getFooterSuccess: false,
  getFooterError: false,
});

const handleGetFooterSuccess = (state, action) => ({
  ...state,
  getFooterRequest: false,
  getFooterSuccess: true,
  getFooterError: false,
  data: action.data,
});

const handleGetFooterError = (state, action) => ({
  ...state,
  getFooterRequest: false,
  getFooterSuccess: false,
  getFooterError: action.error,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.UPDATE_FOOTER]: handleUpdateFooter,
    [actionTypes.GET_FOOTER_REQUEST]: handleGetFooterRequest,
    [actionTypes.GET_FOOTER_SUCCESS]: handleGetFooterSuccess,
    [actionTypes.GET_FOOTER_ERROR]: handleGetFooterError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
