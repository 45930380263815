import { actionTypes } from './actions';

const initialState = {
  user: null,
};

const handleSetData = (state, action) => ({
  ...state,
  user: action.data,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.SET_DATA]: handleSetData,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
