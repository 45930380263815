import React, { Component } from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types'
import { Icons } from '@pik/pik-ui';
import { cookie } from '@pik/pik-utils';
import { hideBannerPavlova } from "../../services/deals/actions";

const { changeSetCookie } = cookie;

import {
  BannerPavlova40Container,
  BannerWrapper,
  BannerContainer,
  BannerClose,
  Text,
  Title,
  BannerIcon,
} from './styles';

const handleClose = (hideBannerPavlovaAction) => {
  changeSetCookie('hidePikBannerPavlova40', true, 30 * 24 * 60 * 60 * 60);
  hideBannerPavlovaAction();
};

const BannerPavlova40 = ({ title, showBanner, hideBannerPavlovaAction }) => {

  if (!showBanner) {
    return null;
  }

  return (
    <BannerPavlova40Container id='BannerPavlova40Container'>
      <BannerWrapper>
        <BannerContainer>
          <BannerIcon>
            <svg width="4" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 9.4V9l.2-.3.4-.2h.8a1 1 0 01.7 1 1 1 0 01-.3.6l-.4.2-.4.1h-.4a1 1 0 01-.4-.3L1 9.8v-.4zm1.8-2.2H1.2L1 .8h2l-.2 6.4z" fill="#fff"/></svg>
          </BannerIcon>
          <Text>
            <Title dangerouslySetInnerHTML={ { __html: title } }/>
          </Text>
        </BannerContainer>
        <BannerClose onClick={ () => handleClose(hideBannerPavlovaAction) }>
          <Icons type='cross' size='xs'/>
        </BannerClose>
      </BannerWrapper>
    </BannerPavlova40Container>
  );

};

BannerPavlova40.propTypes = {
  title      : PropTypes.string,
  showBanner      : PropTypes.bool,
  hideBannerPavlovaAction      : PropTypes.func.isRequired,
};

BannerPavlova40.defaultProps = {
  title      : '<p>Ваш проект &laquo;Павлова 40&raquo; переименован&nbsp;в&nbsp;&laquo;Академика Павлова&raquo;</p>',
  showBanner : false
};

const mapDispatchToProps = {
  hideBannerPavlovaAction: hideBannerPavlova,
};

const mapStateToProps = ({ dealsService }) => ({
  showBanner: dealsService.showBanner,
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerPavlova40);
