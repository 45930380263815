import { actionTypes } from './actions';

const initialState = {
  comparisons: [],
  loading: true,
  error: false,
};

const handleComparisonRequest = (state) => ({
  ...state,
  loading: true,
});

const handleComparisonSuccess = (state, action) => ({
  ...state,
  loading: false,
  comparisons: action.comparisons,
});

const handleComparisonError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_COMPARISON_REQUEST]: handleComparisonRequest,
    [actionTypes.GET_COMPARISON_SUCCESS]: handleComparisonSuccess,
    [actionTypes.GET_COMPARISON_ERROR]: handleComparisonError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
