export const actionTypes = {
  GET_REMINDERS: 'GET_REMINDERS',
  GET_REMINDERS_SUCCESS: 'GET_REMINDERS_SUCCESS',
  GET_REMINDERS_REQUEST: 'GET_REMINDERS_REQUEST',
  GET_REMINDERS_ERROR: 'GET_REMINDERS_ERROR',
};

export const getReminders = (remindersCurrentId) => ({ type: actionTypes.GET_REMINDERS, remindersCurrentId });

export const getRemindersSuccess = (reminders, remindersCurrentId) => ({ type: actionTypes.GET_REMINDERS_SUCCESS, reminders, remindersCurrentId });

export const getRemindersRequest = () => ({ type: actionTypes.GET_REMINDERS_REQUEST });

export const getRemindersError = (remindersError) => ({ type: actionTypes.GET_REMINDERS_ERROR, remindersError });
