import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChatWidget from '@pik/pik-chat-widget';
import { api as apiConstructor } from '@pik/pik-utils';
import { useRouter } from 'next/router';
import disableScrollCssClass from '../../common/disableScrollCssClass';
import apiServices from '../../common/apiServices';
import { showChatFaqModal as showChatFaqModalAction } from '../../services/app/actions';
import { faqTypes, faqConditions } from '../../common/chatFaqConstants';
import { getBookingFullStage } from '../../services/booking/actions';

const api = apiConstructor(apiServices);

const createFaqCondition = (data) => {
  if (data && data.parameters && data.dataFromServer) {
    const { paymentType } = data.parameters;
    const checkSaleScheme = data.dataFromServer.flat && data.dataFromServer.flat.saleScheme;
    const saleScheme = checkSaleScheme ? data.dataFromServer.flat.saleScheme : undefined;

    return {
      paymentType: faqConditions[paymentType],
      saleScheme,
    };
  }

  return undefined;
};

const handleShowChatModal = (showChatModalAction, currentShowChatModal, showChatModal) => {
  if (currentShowChatModal !== showChatModal) {
    showChatModalAction(showChatModal);
  }
};

const handleShowChat = (showChatAction, currentShowChat, showChat) => {
  if (currentShowChat !== showChat) {
    showChatAction(showChat);
  }
};

const createCustomUserData = (fullStage) => {
  if (fullStage && fullStage.name) {
    const { name, description } = fullStage;
    return { stageName: name, stageTitle: description };
  }

  return null;
};

const checkFaqStageExists = (name) => !!Object.keys(faqTypes).find((key) => key === name);

const createFaqStage = ({ stage, fullStage }) => {
  if (stage && fullStage && fullStage.name) {
    const fullStageKey = `${stage}_${fullStage.name}`;

    if (checkFaqStageExists(fullStageKey)) {
      return fullStageKey;
    }
  }

  return stage;
};

const Chat = ({
                customer, data, fullStage, showChatFaqModal, screenType,
                showChatFaqModalAction, getBookingFullStageAction, settings,
                showBenefitsModal,
              }) => {
  const [showChat, showChatAction] = useState(false);
  const [showChatModal, showChatModalAction] = useState(false);
  const isMobile = screenType === 'mobile';

  useEffect(() => {
    if (showChatModal) {
      setTimeout(() => disableScrollCssClass.on(), 500);
    } else {
      setTimeout(() => disableScrollCssClass.off(), 500);
    }
  }, [showChatModal]);

  const stage = data && data.stage ? data.stage : undefined;
  const loading = data && 'loading' in data ? data.loading : undefined;
  const step = data && 'step' in data ? data.step : undefined;
  const saleScheme = data && data.dataFromServer && data.dataFromServer.flat
    ? data.dataFromServer.flat.saleScheme : undefined;
  const checkDduDkp = saleScheme && ['ddu', 'dkp', 'pdkp'].indexOf(saleScheme) !== -1;
  const faqStage = createFaqStage({ stage, fullStage });
  const isFaq = checkDduDkp && faqStage && checkFaqStageExists(faqStage);
  const faqType = faqTypes[faqStage] ? faqTypes[faqStage] : undefined;
  const faqCondition = createFaqCondition(data);
  const customUserData = createCustomUserData(fullStage);

  useEffect(() => {
    if (data) {
      getBookingFullStageAction();
    }
  }, [faqStage, loading, step]);

  const { pathname } = useRouter();

  const zIndex = pathname && pathname.indexOf('booking') !== -1 && pathname !== '/client/booking';

  const checkZIndex = isMobile ? !showChat : !showChatFaqModal;

  let withZIndex = zIndex ? checkZIndex : false;

  if (showBenefitsModal) {
    withZIndex = false;
  }

  return (
    <ChatWidget
      otherLK='forma'
      zIndex={withZIndex && '1500'}
      api={api}
      customer={customer}
      showChat={showChat}
      handleShowChat={(value) => handleShowChat(showChatAction, showChat, value)}
      handleShowChatModal={(value) => handleShowChatModal(showChatModalAction, showChatModal, value)}
      handleShowFaqModal={(value) => showChatFaqModalAction(value)}
      isFaq={isFaq}
      faqType={faqType}
      faqCondition={faqCondition}
      customUserData={customUserData}
      settings={settings}
    />
  );
};

Chat.propTypes = {
  customer: PropTypes.shape({}),
  data: PropTypes.shape({
    stage: PropTypes.string,
    step: PropTypes.string,
    loading: PropTypes.bool,
    dataFromServer: PropTypes.shape({
      flat: PropTypes.shape({
        saleScheme: PropTypes.string,
      }),
    }),
  }),
  settings: PropTypes.shape({}),
  showChatFaqModal: PropTypes.bool.isRequired,
  showChatFaqModalAction: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  customer: null,
  data: null,
  settings: null,
};

function mapStateToProps(state) {
  const {
    customerService: { customer },
    appService: {
      screenType, showChatFaqModal, showBenefitsModal, settings,
    },
    bookingService: { data, fullStage },
  } = state;

  return {
    showChatFaqModal,
    customer,
    screenType,
    data,
    fullStage,
    settings,
    showBenefitsModal,
  };
}

const mapDispatchToProps = {
  showChatFaqModalAction,
  getBookingFullStageAction: getBookingFullStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
