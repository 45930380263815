import { actionTypes } from './actions';

const initialState = {
  friends: null,
  getFriendsRequest: false,
  getFriendsSuccess: false,
  getFriendsError: false,
  friendsPromo: null,
  getFriendsPromoRequest: false,
  getFriendsPromoSuccess: false,
  getFriendsPromoError: false,
  sendCode: false,
  loading: false,
  error: false,
  activatedPromo: false,
  activatePromoLoading: false,
  activatePromoError: false,
};

const handleGetFriendsRequest = (state) => ({
  ...state,
  getFriendsRequest: false,
  getFriendsSuccess: false,
  getFriendsError: false,
});

const handleGetFriendsSuccess = (state, action) => ({
  ...state,
  getFriendsRequest: false,
  getFriendsSuccess: true,
  getFriendsError: false,
  friends: action.friends.data,
});

const handleGetFriendsError = (state, action) => ({
  ...state,
  getFriendsRequest: false,
  getFriendsSuccess: false,
  getFriendsError: action.error,
});

const handleGetFriendsPromoRequest = (state) => ({
  ...state,
  getFriendsPromoRequest: false,
  getFriendsPromoSuccess: false,
  getFriendsPromoError: false,
});

const handleGetFriendsPromoSuccess = (state, action) => ({
  ...state,
  getFriendsPromoRequest: false,
  getFriendsPromoSuccess: true,
  getFriendsPromoError: false,
  friendsPromo: action.friendsPromo,
  sendCode: false,
});

const handleGetFriendsPromoError = (state, action) => ({
  ...state,
  getFriendsPromoRequest: false,
  getFriendsPromoSuccess: false,
  getFriendsPromoError: action.error,
  sendCode: false,
});

const handleSendFriendsRequest = (state) => ({
  ...state,
  sendCode: false,
  loading: true,
  error: false,
});

const handleSendFriendsSuccess = (state) => ({
  ...state,
  sendCode: true,
  loading: false,
  error: false,
});

const handleSendFriendsError = (state, action) => ({
  ...state,
  sendCode: false,
  loading: false,
  error: action.error,
});

const handleActivatePromoRequest = (state) => ({
  ...state,
  activatedPromo: false,
  activatePromoLoading: true,
  activatePromoError: false,
});

const handleActivatePromoSuccess = (state) => ({
  ...state,
  activatedPromo: true,
  activatePromoLoading: false,
  activatePromoError: false,
});

const handleActivatePromoError = (state) => ({
  ...state,
  activatedPromo: false,
  activatePromoLoading: false,
  activatePromoError: true,
});

const handleDeleteErrors = (state) => {
  const { friendsPromo } = state;
  const friendsPromoWithoutErrors = Object.assign(friendsPromo, {});

  if (friendsPromoWithoutErrors
      && friendsPromoWithoutErrors.info && friendsPromoWithoutErrors.info.error) {
    friendsPromoWithoutErrors.info.error = null;
  }

  return ({
    ...state,
    error: false,
    getFriendsPromoError: false,
    friendsPromo: friendsPromoWithoutErrors,
  });
};

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_FRIENDS_REQUEST]: handleGetFriendsRequest,
    [actionTypes.GET_FRIENDS_SUCCESS]: handleGetFriendsSuccess,
    [actionTypes.GET_FRIENDS_ERROR]: handleGetFriendsError,
    [actionTypes.GET_FRIENDS_PROMO_REQUEST]: handleGetFriendsPromoRequest,
    [actionTypes.GET_FRIENDS_PROMO_SUCCESS]: handleGetFriendsPromoSuccess,
    [actionTypes.GET_FRIENDS_PROMO_ERROR]: handleGetFriendsPromoError,
    [actionTypes.SEND_FRIENDS_REQUEST]: handleSendFriendsRequest,
    [actionTypes.SEND_FRIENDS_SUCCESS]: handleSendFriendsSuccess,
    [actionTypes.SEND_FRIENDS_ERROR]: handleSendFriendsError,
    [actionTypes.ACTIVATE_PROMO_REQUEST]: handleActivatePromoRequest,
    [actionTypes.ACTIVATE_PROMO_SUCCESS]: handleActivatePromoSuccess,
    [actionTypes.ACTIVATE_PROMO_ERROR]: handleActivatePromoError,
    [actionTypes.DELETE_ERRORS]: handleDeleteErrors,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
