import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiSms,
} from './api';

import {
  actionTypes,
  getSmsSuccess,
  getSmsRequest,
  getSmsError,
  checkSmsSuccess,
  checkSmsRequest,
  checkSmsError,
} from './actions';

function* getSmsSaga({ phone, captcha }) {
  try {
    yield put(getSmsRequest());
    const data = { phone, service: 'confirmRegistrationSmsPikru', captcha };
    const { message } = yield call(() => apiSms(data));
    yield put(getSmsSuccess(message));
  } catch (error) {
    console.error(error);
    yield put(getSmsError(error));
  }
}

function* checkSmsSaga({ phone, code, captcha }) {
  try {
    yield put(checkSmsRequest());
    const data = {
      phone, code, service: 'confirmRegistrationSmsPikru', captcha,
    };
    const { message, authKey } = yield call(() => apiSms(data));
    yield put(checkSmsSuccess(message, authKey));
  } catch (error) {
    console.error(error);
    yield put(checkSmsError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_SMS, getSmsSaga);
  yield takeEvery(actionTypes.CHECK_SMS, checkSmsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
