export const actionTypes = {
  UPDATE_MENU: 'UPDATE_MENU',
  GET_MENU: 'GET_MENU',
  GET_MENU_REQUEST: 'GET_MENU_REQUEST',
  GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
  GET_MENU_ERROR: 'GET_MENU_ERROR',
  SET_MENU_IS_HIDDEN: 'SET_MENU_IS_HIDDEN',
};

export const getMenu = () => ({ type: actionTypes.GET_MENU });

export const getMenuRequest = () => ({
  type: actionTypes.GET_MENU_REQUEST,
});

export const getMenuSuccess = (data) => ({
  type: actionTypes.GET_MENU_SUCCESS,
  data,
});

export const getMenuError = (error) => ({
  type: actionTypes.GET_MENU_ERROR,
  error,
});

export const setMenuIsHidden = (data) => ({
  type: actionTypes.SET_MENU_IS_HIDDEN,
  data,
});
