import React from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import { auth } from '@pik/pik-utils';
import Menu from '@pik/pik-menu-widget';

import api from '../../common/api';

import { setMenuIsHidden } from '../../services/menu/actions';

const { logout } = auth;

const handleLogout = () => logout({}, '/client');

const CLIENT_BUY_URL = '/client/buy';

const MenuWrapper = ({
  data, customer, screenType, setMenuIsHiddenAction,
}) => {
  const { pathname } = useRouter();
  const isMobile = screenType === 'mobile';
  const hideOnScroll = isMobile && pathname && pathname.includes(CLIENT_BUY_URL);
  if (data) {
    return (
      <Menu
        api={api}
        data={data}
        customer={customer}
        handlers={{
          clickToLogout: handleLogout,
          handleHideOnScroll: (isHidden) => setMenuIsHiddenAction(isHidden),
        }}
        fixed
        // hidePhone
        withoutMobileMenu
        withoutFavourites
        withoutComparison
        fullWidth
        hideOnScroll={hideOnScroll}
        showOnScrollTop={hideOnScroll}
      />
    );
  }

  return null;
};

MenuWrapper.propTypes = {
  data: PropTypes.shape({}),
  customer: PropTypes.shape({}).isRequired,
  screenType: PropTypes.string,
  setMenuIsHiddenAction: PropTypes.func.isRequired,
};

MenuWrapper.defaultProps = {
  screenType: null,
  data: null,
};

const mapStateToProps = ({
  appService,
  menuService,
  customerService,
}) => ({
  screenType: appService.screenType,
  data: menuService.data,
  customer: customerService.customer,
});

const mapDispatchToProps = {
  setMenuIsHiddenAction: setMenuIsHidden,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuWrapper);
