import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetMenu,
} from './api';

import {
  actionTypes,
  getMenuRequest,
  getMenuSuccess,
  getMenuError,
} from './actions';

function* getMenuSaga() {
  try {
    yield put(getMenuRequest());
    const menu = yield call(apiGetMenu);
    if (menu && menu.data) {
      yield put(getMenuSuccess(menu.data));
    }
  } catch (error) {
    console.error(error);
    yield put(getMenuError(error.data));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_MENU, getMenuSaga);
}
