import { actionTypes } from './actions';

const initialState = {
  btiDocuments: null,
  btiDocumentsLoading: false,
  btiDocumentsError: false,
  btiDocumentsCurrentId: null,
};

const handleBtiDocumentsSuccess = (state, { btiDocuments, btiDocumentsCurrentId }) => ({
  ...state,
  btiDocumentsLoading: false,
  btiDocumentsError: false,
  btiDocumentsCurrentId,
  btiDocuments,
});

const handleBtiDocumentsRequest = (state) => ({
  ...state,
  btiDocumentsLoading: true,
  btiDocumentsError: false,
});

const handleBtiDocumentsError = (state, { btiDocumentsError }) => ({
  ...state,
  btiDocuments: [],
  btiDocumentsLoading: false,
  btiDocumentsError,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_BTI_DOCUMENTS_SUCCESS]: handleBtiDocumentsSuccess,
    [actionTypes.GET_BTI_DOCUMENTS_REQUEST]: handleBtiDocumentsRequest,
    [actionTypes.GET_BTI_DOCUMENTS_ERROR]: handleBtiDocumentsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
