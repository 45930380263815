import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import queryString from 'query-string';
import {
  apiRecovery,
  apiRecoveryPut,
  apiRegistration,
  apiRegistrationPassword,
  apiRecoveryPassport,
  apiRecoveryPutPassport,
} from './api';

import { setToken, redirectAuthorizedUser } from '../../common/auth';
import {
  actionTypes,
  changeRecoveryState,
  sendCodeRequest,
  sendCodeSuccess,
  sendCodeError,
  sendApproveCodeRequest,
  sendApproveCodeSuccess,
  sendApproveCodeError,
  sendNewPasswordRequest,
  sendNewPasswordSuccess,
  sendNewPasswordError,
  registrationRequest,
  registrationSuccess,
  registrationError,
  sendRegistrationCodeRequest,
  sendRegistrationCodeSuccess,
  sendRegistrationCodeError,
  sendApproveCodeRegistrationRequest,
  sendApproveCodeRegistrationSuccess,
  sendApproveCodeRegistrationError,
  sendRegistrationNewPasswordRequest,
  sendRegistrationNewPasswordSuccess,
  sendRegistrationNewPasswordError,
} from './actions';

import {
  changeScreenState,
} from '../app/actions';

import {
  screenStates,
  requestCode,
} from './constants';

function checkStatus(data) {
  if (data.message === requestCode.SMS_CODE_SENDED) {
    return changeRecoveryState(screenStates.CODE_APPROVE);
  }

  if (data.message === requestCode.OK && !!data.auth_key) {
    return changeRecoveryState(screenStates.CREATE_NEW_PASSWORD);
  }

  if (data.message === requestCode.PASSWORD_CHANGED) {
    return changeScreenState('AUTH');
  }

  return sendCodeError(data);
}

function createData({ data }) {
  const passport = {};

  passport.series = data.passport.substring(0, 4);
  passport.number = data.passport.substring(5, 11);
  passport.lastname = data.surname;

  return { passport };
}

function* getRecoveryCodeSaga({ phone, captcha }) {
  try {
    yield put(sendCodeRequest(phone));
    const data = yield call(() => apiRecovery({ ...phone, service: 'restorePasswordSms', captcha }));
    yield put(sendCodeSuccess(data));
    yield put(checkStatus(data));
  } catch (error) {
    console.error(error);
    yield put(sendCodeError(error));
  }
}

function* getRecoveryApproveCodeSaga({ dataRequest }) {
  try {
    yield put(sendApproveCodeRequest());
    const data = yield call(() => apiRecovery({ ...dataRequest, service: 'restorePasswordSms' }));
    yield put(sendApproveCodeSuccess(data));
    yield put(checkStatus(data));
  } catch (error) {
    console.error(error);
    yield put(sendApproveCodeError(error));
  }
}

function* getRecoverySendNewPasswordSaga({ dataRequest }) {
  try {
    yield put(sendNewPasswordRequest());
    const query = queryString.parse(window.location.search);
    const { token, expires_in: expiresIn } = yield call(() => apiRecoveryPut(dataRequest));

    if (token) {
      setToken(token, expiresIn);
      redirectAuthorizedUser({ query });
      yield put(sendNewPasswordSuccess());
    }
  } catch (error) {
    console.error(error);
    yield put(sendNewPasswordError(error));
  }
}

function* getPassportApproveSaga(data) {
  try {
    yield put(registrationRequest());
    const result = yield call(() => apiRegistration(createData(data)));
    yield put(registrationSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(registrationError(error));
  }
}

function* getSendRegistrationCodeSaga({ phone }) {
  try {
    yield put(sendRegistrationCodeRequest(phone));
    const result = yield call(() => apiRecoveryPassport(phone));
    yield put(sendRegistrationCodeSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(sendRegistrationCodeError(error));
  }
}

function* getApproveCodeSaga({ data }) {
  try {
    yield put(sendApproveCodeRegistrationRequest());
    const result = yield call(() => apiRecoveryPutPassport(data));
    yield put(sendApproveCodeRegistrationSuccess(result));
  } catch (error) {
    console.error(error);
    yield put(sendApproveCodeRegistrationError(error));
  }
}

function* getRegistrationNewPasswordSaga({ data }) {
  try {
    yield put(sendRegistrationNewPasswordRequest());

    const query = queryString.parse(window.location.search);
    const { token, expires_in: expiresIn } = yield call(() => apiRegistrationPassword({ ...data, service: 'restorePasswordSms' }));

    if (token) {
      setToken(token, expiresIn);
      redirectAuthorizedUser({ query });
      yield put(sendRegistrationNewPasswordSuccess());
    }
  } catch (error) {
    console.error(error);
    yield put(sendRegistrationNewPasswordError(error));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.SEND_RECOVERY_CODE, getRecoveryCodeSaga);
  yield takeEvery(actionTypes.SEND_RECOVERY_APPROVE_CODE, getRecoveryApproveCodeSaga);
  yield takeEvery(actionTypes.SEND_RECOVERY_NEW_PASSWORD, getRecoverySendNewPasswordSaga);
  yield takeEvery(actionTypes.ACTION_APPROVE_PASSPORT_SEND, getPassportApproveSaga);
  yield takeEvery(actionTypes.ACTION_SEND_REGISTRATION_CODE, getSendRegistrationCodeSaga);
  yield takeEvery(actionTypes.ACTION_APPROVE_CODE_REGISTRATION, getApproveCodeSaga);
  yield takeEvery(actionTypes.ACTION_REGISTRATION_NEW_PASSWORD, getRegistrationNewPasswordSaga);
}
