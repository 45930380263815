export const actionTypes = {
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  GET_CUSTOMER: 'GET_CUSTOMER',
  GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
  GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_ERROR: 'GET_CUSTOMER_ERROR',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_ERROR: 'CONFIRM_EMAIL_ERROR',
};

export const updateCustomer = (customer) => ({
  type: actionTypes.UPDATE_CUSTOMER,
  customer,
});

export const getCustomer = () => ({ type: actionTypes.GET_CUSTOMER });

export const getCustomerRequest = () => ({
  type: actionTypes.GET_CUSTOMER_REQUEST,
});

export const getCustomerSuccess = (customer) => ({
  type: actionTypes.GET_CUSTOMER_SUCCESS,
  customer,
});

export const getCustomerError = (error) => ({
  type: actionTypes.GET_CUSTOMER_ERROR,
  error,
});

export const confirmEmail = (params) => ({ type: actionTypes.CONFIRM_EMAIL, ...params });

export const confirmEmailRequest = () => ({ type: actionTypes.CONFIRM_EMAIL_REQUEST });

export const confirmEmailSuccess = (data) => ({ type: actionTypes.CONFIRM_EMAIL_SUCCESS, data });

export const confirmEmailError = (error) => ({ type: actionTypes.CONFIRM_EMAIL_ERROR, error });
