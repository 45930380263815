import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {ModalSimple, Typography} from "@pik/pik-ui";
import {changeHideModal} from "../../services/app/actions";

const Modal = ({ showModal, changeHideModalAction }) => (
  <ModalSimple active={ showModal } onClose={ () => changeHideModalAction() } title={ 'Уважаемые клиенты! ' } overflow='hidden'>
    <div style={{ padding: '20px 0', boxSizing: 'border-box' }}>
      <Typography type='body'>
        Мы обновляем техническую платформу. Возможна некорректная работа онлайн-сервисов. Мы вернемся на связь в ближайшее время!
      </Typography>
    </div>
  </ModalSimple>
);

Modal.propTypes = {
  showModal: PropTypes.bool,
  changeHideModalAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  changeHideModalAction: changeHideModal,
};

const mapStateToProps = ({ appService }) => ({
  showModal: appService.showModal,
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
