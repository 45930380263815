import styled, { createGlobalStyle } from 'styled-components';
import { media, styleHelpers, screenTypes } from '@pik/pik-ui';

export const ToggleButton = styled.button`
  width: 36px;
  height: 36px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 16px 14px;
  
  .Icons {
    transition: transform 0.2s ease;

    ${({ isOpened }) => isOpened && `
      transform: rotateZ(180deg);
    `}
  }
`;

export const Overlay = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 64px;
  width: 100vw;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.40);
  transition: opacity 0.2s ease 0s, left 0.2s, width 0.2s;

  ${({ isOpened }) => isOpened && `
    pointer-events: all;
    opacity: 1;
    left: 240px;
    width: calc(100vw - 240px);
  `}
`;

export const SideBar = styled.div`
  position: fixed;
  z-index: 40;
  width: ${({ isTablet, isOpened }) => (isTablet && !isOpened ? '64px' : '240px')};
  height: calc(100vh - 60px);
  background: #fff;
  top: 60px;
  left: 0;
  border-right: 1px solid #EEEEEE;
  transition: width 0.2s ease;

  @media ${media.max1024} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  ${({ isBottom }) => isBottom && `
    position: absolute;
    bottom: 0;
    top: auto;
  `}
`;

export const List = styled.ul`
  padding: ${({ isTablet, isOpened }) => (isTablet && !isOpened ? '39px 16px 0 16px' : '39px 10px 0 16px')};
`;

export const MenuItem = styled.li`
  user-select: none;
  white-space: nowrap;
  margin-bottom: 15px;
  min-height: 30px;
  
  ${({ active }) => active && `
    .Link {
      color: ${styleHelpers.colors.black} !important;
      font-weight: 500;
      
      svg {
        fill: ${styleHelpers.colors.formaBlack};
        opacity: 1;
      } 
    }
  `}
  
  .Link {
    opacity: 1;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    transition: color .3s ease;
    color: ${styleHelpers.colors.secondaryMedium};
    text-transform: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 29px;
    display: flex;
    align-items: center;

    @media ${media.max1024} {
      padding-left: 4px;
    }
    
    span {
      margin-left: 14px;

      @media ${media.max1024} {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease 0s;
        padding-left: 4px;

        ${({ isOpened }) => isOpened && `
          visibility: visible;
          opacity: 1;
          padding-left: 0;
          transition: opacity 0.2s ease 0.2s, padding 0.2s ease;
        `}
      }
    }

    .IconsPack {
      @media ${media.max1024} {
        flex-shrink: 0;
      }
    }
    
    &:hover {
      color: ${styleHelpers.colors.grayDark};
    }
    
    &-active {
      color: ${styleHelpers.colors.black} !important;
      font-weight: 500;
      
      svg {
        path, circle, rect {
          stroke: ${styleHelpers.colors.formaBlack};
        }
        opacity: 1;
      }
    }
  }
  
  svg {
    fill: ${styleHelpers.colors.secondaryMedium};
    opacity: 0.4;
  } 
  
  .heart-icon {
    svg {
      fill: transparent;
      stroke: ${styleHelpers.colors.secondaryMedium};
    }  
  }
`;
