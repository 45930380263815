import styled from 'styled-components';
import { styleHelpers, mixins, media } from '@pik/pik-ui';

export const BannerPavlova40Container = styled.div`
  height: 64px;
  box-sizing: border-box;
  background: ${styleHelpers.colors.grayExtraLight};
  position: relative;
  border-bottom: 1px solid #F0F0F5;
  padding-top: 64px;
  
  @media ${media.max768} {
    padding: 0 16px;
    margin-top: 41px;
    height: auto;
    
    .Icons {
      fill: #CCCCCC;
    }

  }
`;

export const BannerWrapper = styled.a`
  background: ${styleHelpers.colors.grayExtraLight};
  height: 48px;
  width: 100%;
  ${mixins.flex('', '', 'space-between', 'center')};
  text-decoration: none;
  padding: 0 22px 0 45px;
  box-sizing: border-box;
  
  @media ${media.max768} {
    height: auto;
    padding: 13px 0;
    align-items: flex-start;
  }
`;

export const BannerContainer = styled.div`
  height: 48px;
  ${mixins.flex('', '', 'center', 'center')};

  .Icons {
    fill: #CCCCCC;
    width: 16px;
    height: 16px;
  }

  &:hover {
    .Icons {
      fill: #4D4D4D;
    }
  }
  
  @media ${media.max768} {
    height: auto;
    align-items: flex-start;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  color: #222222;
  letter-spacing: 0;
  line-height: 20px;

  
  @media ${media.max1120} {
    font-size: 20px;
    line-height: 20px;
  }
  
  @media ${media.max1024} {
    font-size: 16px;
    line-height: 16px;
  }
  
  @media ${media.max768} {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14px;
    color: #222222;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #222222;

  @media ${media.max768} {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
`;


export const BannerIcon = styled.div` 
  background-color: ${styleHelpers.colors.secondaryAccent};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  min-width: 24px;
  ${mixins.flex('' ,'', 'center', 'center')};
  margin-right: 12px;

  @media ${media.max768} {
  
  }
`;

export const BannerClose = styled.div` 
  cursor: pointer;
  
  .Icons { 
    fill: ${styleHelpers.colors.secondaryMedium};
  }
  
  @media ${media.max768} {
  
  }
`;
