import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetProjectDocuments,
} from './api';

import {
  actionTypes,
  getProjectDocumentsSuccess,
  getProjectDocumentsRequest,
  getProjectDocumentsError,
} from './actions';

function* getProjectDocumentsSaga({ bulkId, projectDocumentsCurrentId }) {
  try {
    yield put(getProjectDocumentsRequest());
    const projectDocuments = yield call(() => apiGetProjectDocuments(bulkId));
    yield put(getProjectDocumentsSuccess(projectDocuments, projectDocumentsCurrentId));
  } catch (projectDocumentsError) {
    console.error(projectDocumentsError);
    yield put(getProjectDocumentsError(projectDocumentsError));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_PROJECT_DOCUMENTS, getProjectDocumentsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
