export const actionTypes = {
  UPDATE_BOOKING: 'UPDATE_BOOKING',
  GET_BOOKING_FULL_STAGE: 'GET_BOOKING_FULL_STAGE',
  GET_BOOKING_FULL_STAGE_REQUEST: 'GET_BOOKING_FULL_STAGE_REQUEST',
  GET_BOOKING_FULL_STAGE_SUCCESS: 'GET_BOOKING_FULL_STAGE_SUCCESS',
  GET_BOOKING_FULL_STAGE_ERROR: 'GET_BOOKING_FULL_STAGE_ERROR',
  CLEAR_BOOKING: 'CLEAR_BOOKING',
};

export const updateBooking = (data) => ({
  type: actionTypes.UPDATE_BOOKING,
  data,
});

export const getBookingFullStage = () => ({
  type: actionTypes.GET_BOOKING_FULL_STAGE,
});

export const getBookingFullStageRequest = () => ({
  type: actionTypes.GET_BOOKING_FULL_STAGE_REQUEST,
});

export const getBookingFullStageSuccess = (data) => ({
  type: actionTypes.GET_BOOKING_FULL_STAGE_SUCCESS,
  data,
});

export const getBookingFullStageError = (data) => ({
  type: actionTypes.GET_BOOKING_FULL_STAGE_ERROR,
  data,
});

export const clearBooking = () => ({
  type: actionTypes.CLEAR_BOOKING,
});
