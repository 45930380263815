import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetReminders,
} from './api';

import {
  actionTypes,
  getRemindersSuccess,
  getRemindersRequest,
  getRemindersError,
} from './actions';

function* getRemindersSaga({remindersCurrentId}) {
  try {
    yield put(getRemindersRequest());
    const reminders = yield call(() => apiGetReminders());
    yield put(getRemindersSuccess(reminders, remindersCurrentId));
  } catch (remindersError) {
    console.error(remindersError);
    yield put(getRemindersError(remindersError));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_REMINDERS, getRemindersSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
