import { actionTypes } from './actions';

const initialState = {
  data: null,
  loading: false,
  error: null,
  fullStage: null,
};

const handleUpdateBooking = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    ...action.data,
  },
});

const handleGetBookingFullStageRequest = (state) => ({
  ...state,
  loading: true,
});

const handleGetBookingFullStageSuccess = (state, action) => ({
  ...state,
  loading: false,
  fullStage: action.data,
});

const handleGetBookingFullStageError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const handleClearBooking = (state) => ({
  ...state,
  ...initialState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.UPDATE_BOOKING]: handleUpdateBooking,
    [actionTypes.GET_BOOKING_FULL_STAGE_REQUEST]: handleGetBookingFullStageRequest,
    [actionTypes.GET_BOOKING_FULL_STAGE_SUCCESS]: handleGetBookingFullStageSuccess,
    [actionTypes.GET_BOOKING_FULL_STAGE_ERROR]: handleGetBookingFullStageError,
    [actionTypes.CLEAR_BOOKING]: handleClearBooking,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
