export const actionTypes = {
  GET_FRIENDS: 'GET_FRIENDS',
  GET_FRIENDS_REQUEST: 'GET_FRIENDS_REQUEST',
  GET_FRIENDS_SUCCESS: 'GET_FRIENDS_SUCCESS',
  GET_FRIENDS_ERROR: 'GET_FRIENDS_ERROR',
  GET_FRIENDS_PROMO: 'GET_FRIENDS_PROMO',
  GET_FRIENDS_PROMO_REQUEST: 'GET_FRIENDS_PROMO_REQUEST',
  GET_FRIENDS_PROMO_SUCCESS: 'GET_FRIENDS_PROMO_SUCCESS',
  GET_FRIENDS_PROMO_ERROR: 'GET_FRIENDS_PROMO_ERROR',
  SEND_FRIENDS: 'SEND_FRIENDS',
  SEND_FRIENDS_REQUEST: 'SEND_FRIENDS_REQUEST',
  SEND_FRIENDS_SUCCESS: 'SEND_FRIENDS_SUCCESS',
  SEND_FRIENDS_ERROR: 'SEND_FRIENDS_ERROR',
  ACTIVATE_PROMO: 'ACTIVATE_PROMO',
  ACTIVATE_PROMO_REQUEST: 'ACTIVATE_PROMO_REQUEST',
  ACTIVATE_PROMO_SUCCESS: 'ACTIVATE_PROMO_SUCCESS',
  ACTIVATE_PROMO_ERROR: 'ACTIVATE_PROMO_ERROR',
  DELETE_ERRORS: 'DELETE_ERRORS',
};

export const getFriends = () => ({ type: actionTypes.GET_FRIENDS });

export const getFriendsRequest = () => ({
  type: actionTypes.GET_FRIENDS_REQUEST,
});

export const getFriendsSuccess = (friends) => ({
  type: actionTypes.GET_FRIENDS_SUCCESS,
  friends,
});

export const getFriendsError = (error) => ({
  type: actionTypes.GET_FRIENDS_ERROR,
  error,
});

export const getFriendsPromo = () => ({ type: actionTypes.GET_FRIENDS_PROMO });

export const getFriendsPromoRequest = () => ({
  type: actionTypes.GET_FRIENDS_PROMO_REQUEST,
});

export const getFriendsPromoSuccess = (friendsPromo) => ({
  type: actionTypes.GET_FRIENDS_PROMO_SUCCESS,
  friendsPromo,
});

export const getFriendsPromoError = (error) => ({
  type: actionTypes.GET_FRIENDS_PROMO_ERROR,
  error,
});

export const sendFriends = (data) => ({ type: actionTypes.SEND_FRIENDS, data });

export const sendFriendsRequest = () => ({
  type: actionTypes.SEND_FRIENDS_REQUEST,
});

export const sendFriendsSuccess = ({ status }) => ({
  type: actionTypes.SEND_FRIENDS_SUCCESS,
  status,
});

export const sendFriendsError = (error) => ({
  type: actionTypes.SEND_FRIENDS_ERROR,
  error,
});

export const activatePromo = (orderId) => ({ type: actionTypes.ACTIVATE_PROMO, orderId });
export const activatePromoRequest = () => ({
  type: actionTypes.ACTIVATE_PROMO_REQUEST,
});

export const activatePromoSuccess = () => ({
  type: actionTypes.ACTIVATE_PROMO_SUCCESS,
});

export const activatePromoError = (error) => ({
  type: actionTypes.ACTIVATE_PROMO_ERROR,
  error,
});

export const deleteErrors = () => ({ type: actionTypes.DELETE_ERRORS });
