import {
  takeEvery, all, call, put,
} from 'redux-saga/effects';

import {
  apiGetDocuments,
} from './api';

import {
  actionTypes,
  getDocumentsSuccess,
  getDocumentsRequest,
  getDocumentsError,
} from './actions';

function* getDocumentsSaga({ objectId, documentsCurrentId }) {
  try {
    yield put(getDocumentsRequest());
    const documents = yield call(() => apiGetDocuments(objectId));
    yield put(getDocumentsSuccess(documents, documentsCurrentId));
  } catch (documentsError) {
    console.error(documentsError);
    yield put(getDocumentsError(documentsError));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_DOCUMENTS, getDocumentsSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
