export const actionTypes = {
  UPDATE_FOOTER: 'UPDATE_FOOTER',
  GET_FOOTER: 'GET_FOOTER',
  GET_FOOTER_REQUEST: 'GET_FOOTER_REQUEST',
  GET_FOOTER_SUCCESS: 'GET_FOOTER_SUCCESS',
  GET_FOOTER_ERROR: 'GET_FOOTER_ERROR',
};

export const getFooter = () => ({ type: actionTypes.GET_FOOTER });

export const getFooterRequest = () => ({
  type: actionTypes.GET_FOOTER_REQUEST,
});

export const getFooterSuccess = (data) => ({
  type: actionTypes.GET_FOOTER_SUCCESS,
  data,
});

export const getFooterError = (error) => ({
  type: actionTypes.GET_FOOTER_ERROR,
  error,
});
