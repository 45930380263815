import {
  takeEvery, all, call, put, select,
} from 'redux-saga/effects';

import {
  apiGetBookingFullStage,
} from './api';

import {
  actionTypes,
  getBookingFullStageRequest,
  getBookingFullStageSuccess,
  getBookingFullStageError,
} from './actions';

const bookingServiceDataSelector = (state) => state.bookingService.data;

function* getBookingFullStageSaga() {
  try {
    const bookingServiceData = yield select(bookingServiceDataSelector);

    if (bookingServiceData) {
      const { bookingId } = bookingServiceData;

      yield put(getBookingFullStageRequest());

      const response = yield call(() => apiGetBookingFullStage(bookingId));

      if (response && response.error) {
        throw new Error(response.error);
      }

      yield put(getBookingFullStageSuccess(response));
    }
  } catch (error) {
    console.error(error);
    yield put(getBookingFullStageError(error));
  }
}

function* watchSagas() {
  yield takeEvery(actionTypes.GET_BOOKING_FULL_STAGE, getBookingFullStageSaga);
}

export default function* root() {
  yield all([
    watchSagas(),
  ]);
}
