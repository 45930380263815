import { actionTypes } from './actions';

const initialState = {
  reminders: null,
  remindersLoading: false,
  remindersError: false,
  remindersCurrentId: null,
};

const handleRemindersSuccess = (state, { reminders, remindersCurrentId }) => ({
  ...state,
  remindersLoading: false,
  remindersCurrentId,
  reminders,
});

const handleRemindersRequest = (state) => ({
  ...state,
  remindersLoading: true,
});

const handleRemindersError = (state, { remindersError }) => ({
  ...state,
  remindersLoading: false,
  remindersError,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_REMINDERS_SUCCESS]: handleRemindersSuccess,
    [actionTypes.GET_REMINDERS_REQUEST]: handleRemindersRequest,
    [actionTypes.GET_REMINDERS_ERROR]: handleRemindersError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
