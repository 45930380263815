export const actionTypes = {
  GET_DEALS: 'GET_DEALS',
  GET_DEALS_SUCCESS: 'GET_DEALS_SUCCESS',
  GET_DEALS_REQUEST: 'GET_DEALS_REQUEST',
  GET_DEALS_ERROR: 'GET_DEALS_ERROR',
  CHANGE_CURRENT_ID: 'CHANGE_CURRENT_ID',
  HIDE_BANNER_PAVLOVA: 'HIDE_BANNER_PAVLOVA',
};

export const getDeals = (ctx) => ({ type: actionTypes.GET_DEALS, ctx });

export const getDealsSuccess = (deals, ctx, currentId) => ({ type: actionTypes.GET_DEALS_SUCCESS, deals, ctx, currentId });

export const getDealsRequest = () => ({ type: actionTypes.GET_DEALS_REQUEST });

export const getDealsError = (error) => ({ type: actionTypes.GET_DEALS_ERROR, error });

export const changeCurrentId = (currentId) => ({ type: actionTypes.CHANGE_CURRENT_ID, currentId });

export const hideBannerPavlova = () => ({ type: actionTypes.HIDE_BANNER_PAVLOVA });
