import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetFriends,
  apiGetFriendsPromo,
  apiSendFriends,
  apiActivatePromo,
} from './api';

import {
  actionTypes,
  getFriendsRequest,
  getFriendsSuccess,
  getFriendsError,
  sendFriendsRequest,
  sendFriendsSuccess,
  sendFriendsError,
  getFriendsPromoRequest,
  getFriendsPromoSuccess,
  getFriendsPromoError, activatePromoError, activatePromoSuccess, activatePromoRequest,
} from './actions';

function* getFriendsSaga() {
  try {
    yield put(getFriendsRequest());
    const friends = yield call(apiGetFriends);
    yield put(getFriendsSuccess(friends));
  } catch (error) {
    console.error(error);
    yield put(getFriendsError(error && error.data && error.data.error));
  }
}

function* getFriendsPromo() {
  try {
    yield put(getFriendsPromoRequest());
    const friendsPromo = yield call(() => apiGetFriendsPromo());
    yield put(getFriendsPromoSuccess(friendsPromo));
  } catch (error) {
    console.error(error);
    yield put(getFriendsPromoError(error && error.data && error.data.error));
  }
}

function* sendFriendsSaga({ data }) {
  try {
    yield put(sendFriendsRequest());

    const response = yield call(() => apiSendFriends(data));

    yield put(sendFriendsSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(sendFriendsError(error && error.data && error.data.error));
  }
}

function* activatePromoSaga({ orderId }) {
  try {
    yield put(activatePromoRequest());

    yield call(() => apiActivatePromo(orderId));

    yield put(activatePromoSuccess());
  } catch (error) {
    console.error(error);
    yield put(activatePromoError(error && error.data && error.data.error));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_FRIENDS, getFriendsSaga);
  yield takeEvery(actionTypes.GET_FRIENDS_PROMO, getFriendsPromo);
  yield takeEvery(actionTypes.SEND_FRIENDS, sendFriendsSaga);
  yield takeEvery(actionTypes.ACTIVATE_PROMO, activatePromoSaga);
}
