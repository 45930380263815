import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { screenTypes } from '@pik/pik-ui';

import DesktopList from './components/DesktopList';
import MobileList from './components/MobileList';

const Navigation = ({ links, screenType, comparisons }) => {
  if (!screenType) {
    return null;
  }

  if (screenType === screenTypes.MOBILE) {
    return <MobileList comparisons={comparisons} links={links} />;
  }

  return (
    <DesktopList
      comparisons={comparisons}
      links={links}
      screenType={screenType}
    />
  );
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  comparisons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  screenType: PropTypes.string,
};

Navigation.defaultProps = {
  screenType: null,
};

const mapStateToProps = ({ appService, comparisonService }) => ({
  screenType: appService.screenType,
  isNavOpened: appService.isNavOpened,
  comparisons: comparisonService.comparisons,
});

export default connect(mapStateToProps)(Navigation);
