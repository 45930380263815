import { setCookie } from 'nookies';

import { auth } from '@pik/pik-utils';

const MAIN_PATH = '/client/property';

const {
  redirect, getToken, isValidToken, PIK_TOKEN,
} = auth;

export const setToken = (token, expiresIn) => {
  setCookie(null, PIK_TOKEN, token, {
    expires: new Date(expiresIn * 1000),
    path: '/',
  });
};

export const redirectAuthorizedUser = (ctx = {}) => {
  const { callbackPath } = ctx.query;
  redirect(ctx, callbackPath || MAIN_PATH);
};

export const checkAuth = async (ctx, config) => {
  const token = getToken(ctx);

  if (token) {
    const customer = await isValidToken(config.pikApi, token);

    if (!customer) {
      setCookie({}, PIK_TOKEN, '', {
        maxAge: -1,
        path: '/',
      });
      return;
    }

    redirectAuthorizedUser(ctx);
  }
};
