import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import throttle from 'lodash.throttle';
import { withRouter } from 'next/router';

import { Icons, IconsPack, screenTypes } from '@pik/pik-ui';
import Link from '../../../Link';

import {
  List,
  MenuItem,
  SideBar,
  ToggleButton,
  Overlay,
} from './styles';

class DesktopList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBottom: false,
      isOpened: false,
    };
  }

  componentDidMount() {
    const appWrapper = document.getElementById('AppWrapper');

    this.throttledScroll = throttle(this.handleScroll, 50);
    window.addEventListener('scroll', this.throttledScroll);

    this.ro = new ResizeObserver(this.throttledScroll);
    this.ro.observe(appWrapper);

    this.handleScroll();
  }

  componentWillUnmount() {
    const appWrapper = document.getElementById('AppWrapper');

    this.ro.unobserve(appWrapper);
    window.removeEventListener('scroll', this.throttledScroll);
  }

  handleScroll = () => {
    const { isBottom } = this.state;
    const { pageYOffset } = window;
    const appWrapper = document.getElementById('AppWrapper');

    if (!appWrapper) {
      return;
    }

    const wrapperBottom = appWrapper.offsetTop + appWrapper.offsetHeight;
    const wrapperPosition = wrapperBottom - pageYOffset;

    if (!this.listBottom) {
      this.listBottom = this.list.offsetHeight + this.list.offsetTop;
    }

    if (!isBottom && (this.listBottom >= wrapperPosition)) {
      return this.setState({ isBottom: true });
    }

    if (isBottom && (this.listBottom < wrapperPosition)) {
      return this.setState({ isBottom: false });
    }
  };

  changeNavState = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  closeNav = () => {
    this.setState({
      isOpened: false,
    });
  };

  getCurrentActive = (url) => this.props.router.pathname.includes(url);

  renderLink = ({ url, name, icon }) => {
    const { screenType } = this.props;
    const { isOpened } = this.state;

    return (
      <MenuItem
        active={this.getCurrentActive(url)}
        isTablet={screenType === screenTypes.TABLET}
        isOpened={isOpened}
        key={url}
      >
        <Link href={url}>
          <IconsPack className={`${icon}-icon`} name={icon} type="outline" size="l" />
          <span>{name}</span>
        </Link>
      </MenuItem>
    );
  };

  render() {
    const { isBottom, isOpened } = this.state;
    const { links, screenType } = this.props;

    const isTablet = screenType === screenTypes.TABLET;

    return (
      <SideBar
        isOpened={isOpened}
        isTablet={isTablet}
        isBottom={isBottom}
        ref={(el) => this.list = el}
      >
        <List
          isOpened={isOpened}
          isTablet={isTablet}
        >
          {links.map(this.renderLink)}
        </List>

        {isTablet ? (
          <>
            <Overlay
              onClick={this.closeNav}
              isOpened={isOpened}
              isTablet={isTablet}
            />

            <ToggleButton
              isOpened={isOpened}
              onClick={this.changeNavState}
            >
              <Icons type="arrowRight" />
            </ToggleButton>
          </>
        ) : null}
      </SideBar>
    );
  }
}

DesktopList.propTypes = {
  screenType: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  comparisons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  router: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

DesktopList.defaultProps = {
  screenType: null,
};

export default withRouter(DesktopList);
