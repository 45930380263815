import { actionTypes } from './actions';

const initialState = {
  documents: null,
  documentsLoading: false,
  documentsError: false,
  documentsCurrentId: null,
};

const handleDocumentsSuccess = (state, { documents, documentsCurrentId }) => ({
  ...state,
  documentsLoading: false,
  documentsCurrentId,
  documents,
});

const handleDocumentsRequest = (state) => ({
  ...state,
  documentsLoading: true,
});

const handleDocumentsError = (state, { documentsError }) => ({
  ...state,
  documentsLoading: false,
  documentsError,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.GET_DOCUMENTS_SUCCESS]: handleDocumentsSuccess,
    [actionTypes.GET_DOCUMENTS_REQUEST]: handleDocumentsRequest,
    [actionTypes.GET_DOCUMENTS_ERROR]: handleDocumentsError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
