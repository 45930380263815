import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetFooter,
} from './api';

import {
  actionTypes,
  getFooterRequest,
  getFooterSuccess,
  getFooterError,
} from './actions';

function* getFooterSaga() {
  try {
    yield put(getFooterRequest());
    const footer = yield call(apiGetFooter);
    if (footer && footer.content) {
      yield put(getFooterSuccess(footer.content));
    }
  } catch (error) {
    console.error(error);
    yield put(getFooterError(error.data));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_FOOTER, getFooterSaga);
}
