import { actionTypes } from './actions';

const initialState = {
  loading: false,
  error: null,
};

const handleSendAuthRequest = () => ({
  loading: true,
  error: null,
});

const handleSendAuthSuccess = () => ({
  loading: true,
  error: null,
});

const handleSendAuthError = (state, action) => ({
  loading: false,
  error: action.error,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.SEND_AUTH_REQUEST]: handleSendAuthRequest,
    [actionTypes.SEND_AUTH_SUCCESS]: handleSendAuthSuccess,
    [actionTypes.SEND_AUTH_ERROR]: handleSendAuthError,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
