import clientRendering from './clientRendering';

export default (callback, container, parent, isSlider) => {
  if (!clientRendering) {
    return () => {};
  }

  let touchStartY = 0;
  let touchEndY = 0;

  const element = parent || document.body;

  const touchStart = (event) => {
    touchStartY = event.pageY;

    if (isSlider) {
      subscribe(event);
    }
  };

  const subscribe = (event) => {
    if (!!container && !container.contains(event.target)) {
      callback(event);
    }
  };

  const touchEnd = (event) => {
    touchEndY = event.pageY;

    const scrollUp = touchEndY < touchStartY;
    const scrollDown = touchEndY > touchStartY;

    if (scrollUp === scrollDown) {
      subscribe(event);
    }
  };

  element.addEventListener('click', subscribe);
  element.addEventListener('touchstart', touchStart);
  element.addEventListener('touchend', touchEnd);

  return () => {
    element.removeEventListener('click', subscribe);
    element.removeEventListener('touchstart', touchStart);
    element.removeEventListener('touchend', touchEnd);
  };
};
