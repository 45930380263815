import {
  createStore,
  applyMiddleware,
  combineReducers,
} from 'redux';

import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import app from './app';
import booking from './booking';
import deals from './deals';
import customer from './customer';
import comparison from './comparison';
import footer from './footer';
import menu from './menu';
import documents from './documents';
import reminders from './reminders';
import projectDocuments from './projectDocuments';
import btiDocuments from './btiDocuments';
import measure from './measure';
import auth from './auth';
import recovery from './recovery';
import sms from './sms';
import data from './data';
import friends from './friends';

const rootReducer = combineReducers({
  appService: app.reducer,
  bookingService: booking.reducer,
  dealsService: deals.reducer,
  customerService: customer.reducer,
  comparisonService: comparison.reducer,
  footerService: footer.reducer,
  menuService: menu.reducer,
  measureService: measure.reducer,
  documentsService: documents.reducer,
  remindersService: reminders.reducer,
  projectDocumentsService: projectDocuments.reducer,
  btiDocumentsService: btiDocuments.reducer,
  authService: auth.reducer,
  smsService: sms.reducer,
  dataService: data.reducer,
  recoveryService: recovery.reducer,
  friendsService: friends.reducer,
});

function* rootSaga() {
  yield all([
    deals.sagas(),
    booking.sagas(),
    customer.sagas(),
    comparison.sagas(),
    footer.sagas(),
    menu.sagas(),
    documents.sagas(),
    reminders.sagas(),
    measure.sagas(),
    projectDocuments.sagas(),
    btiDocuments.sagas(),
    auth.sagas(),
    recovery.sagas(),
    sms.sagas(),
    friends.sagas(),
  ]);
}

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([sagaMiddleware]),
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
