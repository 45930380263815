import clientRendering from './clientRendering';

export default {
  on() {
    if (clientRendering) {
      const html = document.getElementsByTagName('html')[0];
      const app = document.getElementsByTagName('div')[0];
      const scrollVisible = window.innerWidth - document.documentElement.clientWidth;

      html.classList.add('noscroll');

      if (scrollVisible) {
        html.classList.add('scroll-visible');
        app.style.paddingRight = `${scrollVisible}px`;
      }
    }
  },
  off() {
    if (clientRendering) {
      const html = document.getElementsByTagName('html')[0];
      const app = document.getElementsByTagName('div')[0];

      html.classList.remove('noscroll');
      html.classList.remove('scroll-visible');
      app.style.paddingRight = '0';
    }
  },
};
