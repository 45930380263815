import { actionTypes } from './actions';

const initialState = {
  data: null,
  getMenuRequest: false,
  getMenuSuccess: false,
  getMenuError: false,
  isHidden: false,
};

const handleUpdateMenu = (state, action) => ({
  ...state,
  data: action.data,
});

const handleGetMenuRequest = (state) => ({
  ...state,
  getMenuRequest: false,
  getMenuSuccess: false,
  getMenuError: false,
});

const handleGetMenuSuccess = (state, action) => ({
  ...state,
  getMenuRequest: false,
  getMenuSuccess: true,
  getMenuError: false,
  data: action.data,
});

const handleGetMenuError = (state, action) => ({
  ...state,
  getMenuRequest: false,
  getMenuSuccess: false,
  getMenuError: action.error,
});

const handleSetMenuIsHidden = (state, action) => ({
  ...state,
  isHidden: action.data,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.UPDATE_MENU]: handleUpdateMenu,
    [actionTypes.GET_MENU_REQUEST]: handleGetMenuRequest,
    [actionTypes.GET_MENU_SUCCESS]: handleGetMenuSuccess,
    [actionTypes.GET_MENU_ERROR]: handleGetMenuError,
    [actionTypes.SET_MENU_IS_HIDDEN]: handleSetMenuIsHidden,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
