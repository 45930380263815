export const actionTypes = {
  GET_DOCUMENTS: 'GET_DOCUMENTS',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_REQUEST: 'GET_DOCUMENTS_REQUEST',
  GET_DOCUMENTS_ERROR: 'GET_DOCUMENTS_ERROR',
};

export const getDocuments = (objectId, documentsCurrentId) => ({ type: actionTypes.GET_DOCUMENTS, objectId, documentsCurrentId });

export const getDocumentsSuccess = (documents, documentsCurrentId) => ({ type: actionTypes.GET_DOCUMENTS_SUCCESS, documents, documentsCurrentId });

export const getDocumentsRequest = () => ({ type: actionTypes.GET_DOCUMENTS_REQUEST });

export const getDocumentsError = (documentsError) => ({ type: actionTypes.GET_DOCUMENTS_ERROR, documentsError });
