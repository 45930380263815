export const faqTypes = {
  parameters: 'clientOnlineParameters',
  participants: 'clientOnlineParticipants',
  participantsLoading: 'clientOnlineParticipantsLoading',
  payment: 'clientOnlinePayment',
  coordination: 'clientOnlineCoordination',
  coordinationLoading: 'clientOnlineCoordination',
  signature: 'clientOnlineSignature',
  signatureLoading: 'clientOnlineSignature',
};

export const faqConditions = {
  111: 'fullPayment',
  112: 'installment',
  113: 'mortgage',
};
