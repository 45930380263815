export default {
  pikApi: 'https://api.forma.ru',
  pikApiSocket: 'https://auction.ws2.pikweb.net',
  calculatorApi: 'https://calculator.pik-service.ru',
  pikStrapi: 'https://strapi.pik.ru',
  pikChatApi: 'https://chat-digital.pik.ru',
  requestProxy: 'https://proxyapi.pik.ru',
  pikSvg: '',
  uploadApi: 'https://upload.pik.ru',
};
