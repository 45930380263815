import {
  takeEvery, call, put,
} from 'redux-saga/effects';

import {
  apiGetComparison,
} from './api';

import {
  actionTypes,
  getComparisonSuccess,
  getComparisonRequest,
  getComparisonError,
} from './actions';

function* getComparisonSaga() {
  try {
    yield put(getComparisonRequest());
    const comparisons = yield call(apiGetComparison);

    yield put(getComparisonSuccess(comparisons.flats));
  } catch (error) {
    console.error(error);
    yield put(getComparisonError(error.data));
  }
}

export default function* watchSagas() {
  yield takeEvery(actionTypes.GET_COMPARISON, getComparisonSaga);
}
